#navbar {
    font-family: 'Montserrat', sans-serif;
}


.logo {
  position: absolute;
  width: 50px;
  height: 45px;
  margin-left: -3rem;
  margin-bottom: 3rem;
}

@media screen and (max-width: 768px) {
  .logo {
    display: none;
  }

  #drop-title {
    padding-left: 3rem;
  }

}

@media screen and (max-width: 1300px) {
  .logo {
    display: none;
  }
}
