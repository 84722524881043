.mentions {
  display: flex;
  height: 80vh;
  flex-direction: column;
  align-items: center;
}

.mentions-title {
  margin-top: 3rem;
}

.mentions-text {
  margin-top: 2rem;
  padding: 0;
  max-width: 70%;
  line-height: 3;
}

@media screen and (max-width: 760px) {
  .mentions {
    height: 100%;
  }
}