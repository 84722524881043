.coffe {
  padding: 60px 0;
  font-family: "Montserrat", sans-serif;
  background: url(../../../assets/img/students.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.coffe-container {
  margin: 0 5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
}

.coffe-card {
  margin: 5rem 0;
  background: linear-gradient(
    90deg,
    rgba(158, 158, 161, 1) 0%,
    rgba(192, 190, 195, 1) 19%,
    rgba(237, 230, 230, 0.6334908963585435) 35%,
    rgba(248, 245, 249, 0.5676645658263305) 56%
  );
  border-radius: 12px;
  box-shadow: 10px 3px 5px black;
  padding: 3rem 1rem;
  opacity: 0.8;
}

.coffe-text {
  font-weight: 500;
}

.coffe-card img {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border-radius: 40x;
}

.coffe-title {
  text-align: center;
  margin: 0;
  padding: 2rem 0;
  font-size: 2rem;
  text-align: center;
  font-weight: 500;
  background: linear-gradient(
    90deg,
    rgba(158, 158, 161, 1) 0%,
    rgba(192, 190, 195, 1) 19%,
    rgba(237, 230, 230, 0.6334908963585435) 35%,
    rgba(248, 245, 249, 0.5676645658263305) 56%
  );
  border-radius: 15px;
  box-shadow: 5px 3px 4px 2px black;
}

.coffe h3 {
  font-size: 1.3rem;
  text-align: center;
  margin-top: 1rem;
}

.coffe-coffe {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 500;
  font-size: 16px;
}

.coffe img {
  max-width: 300px;
  max-height: 200px;
}

.coffe-list-button {
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.coffe-button {
  padding: 1rem 3rem;
  background-color: black;
  color: white;
}

/* Responsive */

@media screen and (max-width: 740px) {
  .coffe-container {
    margin: auto 0;
    display: flex;
    flex-direction: column;
  }

  .coffe-title {
    margin-bottom: 2rem;
    font-size: 1rem;
    padding: 1rem 0;
  }

  .coffe-card {
    margin: 1rem 2rem;
  }
}

@media screen and (min-width: 760px) and (max-width: 1280px) {
  .coffe-container {
    display: flex;
    flex-direction: column;
    padding: 1rem 5rem;
  }

  .coffe-title {
    font-size: 1.5rem;
    padding: 1rem 0;
  }

  .coffe-card {
    margin: 2rem 2rem;
  }
}

/* ANIMATION */

.coffe {
  animation: fadein 3s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari et Chrome */
  -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}

