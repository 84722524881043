.footer {
  width: 100%;
  height: 10vh;
  background-color: rgb(247, 241, 241);
  margin: 0;
  padding: 0;
}

.footer-container {
  width: 100%;
  background-color: rgb(247, 241, 241);
}

.footer h4 {
  font-size: 1rem;
  padding: 1rem 1rem;
}

.footer-list {
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.footer-link {
  text-decoration: none;
  color: black;
  line-height: 1.8;
  font-size: 1rem;
  padding: 0 2rem;
}


@media screen and (max-width: 940px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-list {
    display: flex;
    flex-direction: column;
  }

}
