.prices {
  display: flex;
  flex-direction: column;
  background: url(../../../assets/img/students.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  width: 100%;
  height: 100%;
}

.prices-title {
  margin-top: 3rem;
  text-align: center;
  padding: 2rem 0;
  font-size: 2rem;
  text-align: center;
  font-weight: 500;
  background: linear-gradient(
    90deg,
    rgba(158, 158, 161, 1) 0%,
    rgba(192, 190, 195, 1) 19%,
    rgba(237, 230, 230, 0.6334908963585435) 35%,
    rgba(248, 245, 249, 0.5676645658263305) 56%
  );
  border-radius: 15px;
  box-shadow: 5px 3px 4px 2px black;
  margin-bottom: 3rem;
}

img {
  margin-top: 3rem;
  width: 730px;
  height: 474px;
}

.prices-list {
  margin: 0 auto;
  padding: 2rem 0;
  flex-direction: column;
  list-style: none;
  background: linear-gradient(
    90deg,
    rgba(158, 158, 161, 1) 0%,
    rgba(192, 190, 195, 1) 19%,
    rgba(237, 230, 230, 0.6334908963585435) 35%,
    rgba(248, 245, 249, 0.5676645658263305) 56%
  );
  border-radius: 15px;
  box-shadow: 5px 3px 4px 2px black;
  width: 900px;
}

.prices-item {
  text-align: start;
  margin: 0 auto;
  max-width: 80%;
  font-weight: 400;
  line-height: 2;
}

.conditions-item {
  text-align: start;
  font-weight: 400;
  margin: 0 auto;
  max-width: 80%;
}

.conditions {
  margin-top: 40px;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .prices {
    display: flex;
    max-width: 100%;
    height: auto;
  }

  .prices-title {
    font-size: 1rem;
    padding: 1rem 0;
  }

  .menu-img {
    display: flex;
    justify-content: center;
  }

  .prices img {
    width: 300px;
    height: auto;
  }

  .prices-list {
    max-width: 80%;
    padding: 1rem 1rem;
  }

  .conditions {
    font-size: 1rem;
    font-weight: 600;
  }
}

@media screen and (min-width: 770px) and (max-width: 1200px) {
  .home-links li {
    font-size: 1rem;
  }
}

/* ANIMATION */

.prices {
  animation: fadein 3s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari et Chrome */
  -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
