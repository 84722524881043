.teacher {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
  background-image: url(../../../assets/img/homeback.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.teacher-container {
  max-width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: transparent;
  background: linear-gradient(
    90deg,
    rgba(158, 158, 161, 1) 0%,
    rgba(192, 190, 195, 1) 19%,
    rgba(237, 230, 230, 0.6334908963585435) 35%,
    rgba(248, 245, 249, 0.5676645658263305) 56%
  );
  border-radius: 15px;
  box-shadow: 5px 3px 4px 2px black;
  padding: 5rem 2rem;
  margin-top: 3rem;
}

.teacher h2 {
  padding-bottom: 2rem;
}

.teacher-list {
  font-size: 18px;
  max-width: 700px;

}

.teacher-link {
  text-decoration: underline;
  cursor: pointer;
}

.teacher img {
  width: 200px;
  height: 200px;
  margin-bottom: 1rem;
}

.teacher button {
  margin-top: 2rem;
  padding: 1rem 3rem;
  background-color: black;
  color: white;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .teacher {
    margin: 0 auto;
    text-align: center;
    max-width: 100%;
    height: auto;
  }

  .teacher-container {
    margin-top: 1rem;
    max-width: 80%;
  }

  .teacher h2 {
    margin-top: 2rem;
  }

  .teacher img {
    width: 150px;
    height: 150px;
  }

  .teacher-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    border: none;
  }

  .teacher-list {
    font-size: 1rem;
    padding: 1rem 2rem;
    text-align: left;
    margin: 0;
  }

  .home-links {
    display: flex;
    flex-direction: column;
  }

  .home-links li {
    font-size: 15px;
    padding: 0.5rem 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .teacher-container {
    margin-top: 3rem;
  }
}

/* ANIMATION */

.teacher {
  animation: fadein 3s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari et Chrome */
  -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
