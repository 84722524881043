.contact {
  padding: 60px 0;
  font-family: "Montserrat", sans-serif;
  background: url(../../../assets/img/pantheonflout.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-title {
  margin-bottom: 3rem;
}
label {
  margin-bottom: 0.5rem;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding: 4rem 1rem;
  margin: auto;
  margin-top: 3rem;
  background: linear-gradient(
    90deg,
    rgba(158, 158, 161, 1) 0%,
    rgba(192, 190, 195, 1) 19%,
    rgba(237, 230, 230, 0.6334908963585435) 35%,
    rgba(248, 245, 249, 0.5676645658263305) 56%
  );
  border-radius: 12px;
  box-shadow: 10px 3px 5px black;
  opacity: 1;
}

form input,
form textarea {
  margin-bottom: 1rem;
  padding: 10px 18px;
  font-size: 1.2rem;
  background-color: rgb(255, 255, 255, 0.1);
  border-color: rgb(255, 255, 255, 0.1);
}

.contact-button {
  width: 150px;
  margin: 0 auto;
  margin-top: 3rem;
  padding: 1rem 1rem;
  background-color: black;
  color: white;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .form {
    margin: 0 2rem;
  }
}

/* ANIMATION */

.contact {
  animation: fadein 3s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari et Chrome */
  -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
